<template lang="pug">
  section.hero
    CharityPop(
      :is-show-popup="isShowPopup"
      @onClose="isShowPopup = false"
    )

    .hero__container
      .hero__content
        h1.hero__title.title
          | Приєднуйся до команди
          |
          span VIDAR
        .hero__text
          p Виберіть напрямок і завітайте на безкоштовні заняття
        .hero__actions
          a.hero__link.link(href="tel:+380687835335") Записатись
          button.hero__link.link.hero__link-white(@click="isShowPopup = true") Благодійний збір
</template>

<script>
export default {
  name: 'HeroBlock',
  components: {
    CharityPop: () => import('@/components/CharityPop.vue')
  },
  data () {
    return {
      isShowPopup: false
    }
  },
  mounted () {
    this.isShowPopup = this.$route.name === 'HomeExtra'
  }
}
</script>
